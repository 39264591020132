import React, {useContext} from "react"
import CommittedCounter from "./committedCounter"
import cartTableStyles from '../../styles/carrello/cart-table.module.scss'
import Img from "gatsby-image"
import CloseIcon from "../../assets/close.svg"
import { StoreContext } from "../../context/storeContext"

const CartTable = ({products,  cssClass}) => {
  const { removeItemFromCommittedCart } = useContext(StoreContext)
  return ( 
      <div className={cssClass}>
         {products.map(item => (
          <div key={item.strapiId} className={cartTableStyles.row}>
            <div  className={cartTableStyles.fotoBox}>
              <Img
                className={cartTableStyles.foto}
                fluid={item.foto.childImageSharp.fluid}
                alt={item.foto.alternativeText}
              />
            </div>
            <div className={cartTableStyles.name}>{item.nome}</div>
           <CommittedCounter
              product={item}
              cssClassCounter={cartTableStyles.counter}
              cssClassBg={cartTableStyles.bg}
              cssClass={cartTableStyles.wrapper}
              cssClassBig={cartTableStyles.big}
              cssClassQty={cartTableStyles.qty}
              cssClassTotal={cartTableStyles.itemTotal}
              cssClassBigBtn={cartTableStyles.bigBtn}
              cssClassBigBtnLabel={cartTableStyles.bigBtnLabel}
            />
            <div className={cartTableStyles.closeItem} onClick={() => removeItemFromCommittedCart(item)}><CloseIcon className={cartTableStyles.icon} /></div>
          </div>
        ))}
      </div>
  )
}

export default CartTable