import React, { useContext } from "react"
import { formatPrice } from "../../utilities/formatPrice"
import { StoreContext } from "../../context/storeContext"

const CommittedCounter = ({
  product,
  cssClass,
  cssClassCounter,
  cssClassBg,
  cssClassBig,
  cssClassQty,
  cssClassTotal,
  cssClassBigBtn,
  cssClassBigBtnLabel,
}) => {
  const { committedCart, addQtyToCommittedCart, removeQtyFromCommittedCart } = useContext(StoreContext)
  const productCount = committedCart.find(el => el.strapiId === product.strapiId)
    ? committedCart.find(el => el.strapiId === product.strapiId).quantity
    : 0
  const productTotalPrice = productCount * product.prezzo

  return (
    <>
      <div className={cssClass}>
        <div className={cssClassCounter}>
          <button
            onClick={() => removeQtyFromCommittedCart(product)}
            className={cssClassBigBtn}
          >
            <span
              className={cssClassBigBtnLabel}
            >
              -
            </span>
          </button>

          <span className={cssClassQty}>
            {productCount}
          </span>
          <button
            onClick={() => addQtyToCommittedCart(product)}
            className={cssClassBigBtn}
          >
            {" "}
            <span
              className={cssClassBigBtnLabel}
            >
              +
            </span>
          </button>
        </div>
        <span className={cssClassTotal}>
          tot.{" "}
          <span className={cssClassBig}>
            {formatPrice(productTotalPrice)}
          </span>
        </span>
      </div>
      <div className={cssClassBg}></div>
    </>
  )
}

export default CommittedCounter