import React from "react"
import {Link} from'gatsby'
import promozioniStyles from "../../styles/home/promozioni.module.scss"
import markdownStyles from "../../styles/common/markdown.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown"

const Promozioni = () => {

    const data = useStaticQuery(graphql`
    query PromozioniQuery {
      strapiPromozioni {
          pulsante {
            testo
            link
          }
        }
      }
    `)

  const numPromo = data.strapiPromozioni.pulsante.length

  return (
    <div className={promozioniStyles.promozioni}>
     { data.strapiPromozioni.pulsante.map( btn => 
       ( <Link key={btn.link} className={numPromo <= 2 ? promozioniStyles.btnSmall : promozioniStyles.btn} to={`/${btn.link}`}>
            <Markdown className={`${promozioniStyles.testo} ${markdownStyles.testo}`}>{btn.testo}</Markdown>
        </Link>))}
    </div>)
}

export default Promozioni 