import React, { useContext } from "react"
import { StoreContext } from "../context/storeContext"
import cartStyles from '../styles/pages/carrello.module.scss'
import { formatPrice } from "../utilities/formatPrice"
import Layout from "../components/common/layout"
import CartTable from "../components/carrello/cartTable"
import {Link} from 'gatsby'
import Promozioni from "../components/home/promozioni"


const Carrello = ({ location }) => {
  const { committedCart, cartCount, getCartTotal } = useContext(StoreContext)
  const cartTotal = getCartTotal(committedCart)
  
  return (
    <Layout
      pageMeta={{
        title: "Carrello",
        keywords: [
          "massoterapia",
          "Savona",
          "riflessologia plantare",
          "counseling",
        ],
        description:
          "Studio di massoterapia e riflessologia plantare emozionale",
        link: "/carrello",
      }}
      location={location}> 
        <Promozioni/>
        <h1 className="page-title">Il carrello</h1>
        {committedCart.length === 0 && <p className={cartStyles.text}>Hai 0 trattamenti nel carrello</p>}
        {committedCart.length === 1 && <p className={cartStyles.text}>Hai {committedCart && cartCount} trattamento nel carrello</p>}
        {committedCart.length !== 1 && committedCart.length !== 0 && <p className={cartStyles.text}>Hai {committedCart && cartCount} trattamenti nel carrello</p>}
        <div className={cartStyles.layoutPage}>
          <div className={cartStyles.layoutCart}>
{/*               <div className={cartStyles.btnWrapper}>
                <Link className={cartStyles.btnBack} to="/trattamenti">torna allo shop</Link>
              </div> */}
                {committedCart.length !== 0 &&  
              ( <><h3 className={cartStyles.categoryTitle}>i trattamenti che hai scelto</h3>
              <CartTable cssClass={cartStyles.cartTable} products={committedCart} /> </>)}         
              {committedCart.length !== 0 && <h3 className={cartStyles.totalRight}>totale da pagare {committedCart && formatPrice(cartTotal)}</h3>}
              {committedCart.length !== 0 && <p className={cartStyles.totalRightNote}>tutti i prezzi sono IVA esenti</p>}
              {committedCart.length !== 0 && <div className={cartStyles.btnWrapper}><Link className={cartStyles.btnCheckout} to="/checkout">Prosegui al Checkout</Link> </div>}
          </div>
        </div>
      </Layout>
  )
}

export default Carrello